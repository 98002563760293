<template>
  <div v-loading="is_panding" v-if="desktop" class="manage-shinia-container">
    <div class="header">
      <div class="search">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="search" type="text" placeholder="חיפוש" />
          <InputText  style="margin: 0 5px;"  v-model="start_date" type="date" placeholder="מתאריך" />
          <InputText   v-model="end_date" type="date" placeholder="עד תאריך" />
          <Dropdown filter  showClear style="margin: 0 5px;" v-model="from_branch" :options="brnaches_options"  placeholder="מסניף" checkmark :highlightOnSelect="false" class="w-full md:w-14rem" />
          <Dropdown showClear  filter  style="margin: 0 5px;" v-model="to_branch" :options="brnaches_options"  placeholder="לסניף" checkmark :highlightOnSelect="false" class="w-full md:w-14rem" />
          <Dropdown showClear  filter  style="margin: 0 5px;" v-model="selected_status" :options="['חדש','טופל','לא טופל','הודפס','בוטל']"  placeholder="סטטוס" checkmark :highlightOnSelect="false" class="w-full md:w-14rem" />
        </span>
      </div>
    </div>
    <div class="content">
      <table>
        <tr>
          <th @click="handleSortTable('תאריך', !sortedByclick.order)">
            תאריך
          </th>
          <th>
            זמן
          </th>
          <th @click="handleSortTable('מסניף', !sortedByclick.order)">
            מסניף
          </th>
          <th @click="handleSortTable('לסניף', !sortedByclick.order)">
            לסניף
          </th>

          <th @click="handleSortTable('פותח הקריאה', !sortedByclick.order)">
            פותח/ת הקריאה
          </th>
          <th @click="handleSortTable('דואל', !sortedByclick.order)">
            דוא"ל
          </th>
          <th @click="handleSortTable('טלפון', !sortedByclick.order)">
            טלפון
          </th>
          <th @click="handleSortTable('דחיפות', !sortedByclick.order)">
            דחיפות
          </th>
          <th @click="handleSortTable('הערות', !sortedByclick.order)">
            הערות
          </th>
          <th @click="handleSortTable('סטטוס', !sortedByclick.order)">
            סטטוס
          </th>
          <th>
            תמונות
          </th>
          <th>משוב</th>
          <th>לוגיסטיקה ({{total_price}})</th>
        </tr>
        <template v-for="detail in filteredDataByDates" :key="detail.id">
          {{detail.cost}}
          <tr v-if="selected_status && detail.status == selected_status">
            <td>{{ detail.createdAt.toDate().toLocaleDateString("he") }}</td>
            <td>{{ detail.createdAt.toDate().toLocaleTimeString("he") }}</td>
            <td>{{ detail.selectedBranchFrom }}</td>
            <td>{{ detail.selectedBranchTo }}</td>
            <td>{{ detail.name }}</td>
            <td>{{ detail.email }}</td>
            <td>{{ detail.phone }}</td>
            <td>{{ detail.urgency }}</td>
            <td>{{ detail.comments }}</td>
            <td v-if="detail.status == 'חדש'" style="color:green;">
              {{ detail.status }}
            </td>
            <td v-if="detail.status == 'טופל'" style="color:red;">
              {{ detail.status }}
            </td>
            <td v-if="detail.status == 'לא טופל'" style="color:#FAB710;">
              {{ detail.status }}
            </td>
            <td v-if="detail.status == 'הודפס'" style="color: blue;">
              {{ detail.status }}
            </td>
            <td style="text-align:center;">
              <i @click="selected_display_images=detail.images" v-if="detail.images && detail.images.some(img=>img.url)" class="material-icons" style="color:var(--blue)">image</i>
              <i v-else class="material-icons" style="color:var(--danger)">image_not_supported</i>
            </td>
            <td >
              <i @click="handlePrint(detail)" class="pi pi-print" style="margin: 5px;"></i>
              <i @click="handleMashov(detail)" class="pi pi-reply" style="margin: 5px;"></i>
            </td>
            <td style="width: 50px;">
              <el-input   v-model="detail.price" type="number"  :min="0"  @change="handle_change_price(detail)"  />
            </td>
          </tr>
          <tr
            v-if="
              !selected_status
            "
          >
            <td>{{ detail.createdAt.toDate().toLocaleDateString("he") }}</td>
            <td>{{ detail.createdAt.toDate().toLocaleTimeString("he") }}</td>
            <td>{{ detail.selectedBranchFrom }}</td>
            <td>{{ detail.selectedBranchTo }}</td>
            <td>{{ detail.name }}</td>
            <td>{{ detail.email }}</td>
            <td>{{ detail.phone }}</td>
            <td>{{ detail.urgency }}</td>
            <td>{{ detail.comments }}</td>
            <td v-if="detail.status == 'חדש'" style="color:green;">
              {{ detail.status }}
            </td>
            <td v-if="detail.status == 'טופל'" style="color:red;">
              {{ detail.status }}
            </td>
            <td v-if="detail.status == 'לא טופל'" style="color:#FAB710;">
              {{ detail.status }}
            </td>           
            <td v-if="detail.status == 'הודפס'" style="color: blue;">
              {{ detail.status }}
            </td>
            <td style="text-align:center;">
              <i @click="selected_display_images=detail.images" v-if="detail.images && detail.images.some(img=>img.url)" class="material-icons" style="color:var(--blue)">image</i>
              <i v-else class="material-icons" style="color:var(--danger)">image_not_supported</i>
            </td>
            <td >
              <i @click="handlePrint(detail)" class="pi pi-print" style="margin: 5px;"></i>
              <i @click="handleMashov(detail)" class="pi pi-reply" style="margin: 5px;"></i>
            </td>
            <td style="width: 50px;">
              <el-input  v-model="detail.price" type="number"  :min="0"  @change="handle_change_price(detail)"  />
            </td>
          </tr>
        </template>
      </table>
    </div>
  </div>
  
  <DisplayImages @close="selected_display_images=null" v-if="selected_display_images" :images="selected_display_images"/>
  <OnlyForDesktop v-if="!desktop" />

  <Dialog header="משוב" v-model:visible="showMashov">
    <div class="mashov-container">
      <div class="explaination">
        <h4>במסך זה עליך לסיים את תהליך השינוע במערכת</h4>
        <h4>
          כדי לסיים את תהליך השינוע עליך למלא את השדה מתחת בהערותיך לשינוע זה
        </h4>
        <h4>כאשר הינך מסיים לכתוב את הערותך לחץ על כפתור סיים תהליך</h4>
        <h4>מייל לאישור סיום התהליך יישלח לפותח/ת הקריאה</h4>
        <h2>נא לשים לב!</h2>
        <h2>
          באפשרותך למחוק את הקריאה ע"י לחיצה על כפתור מחק הנמצא בתחתית החלון
        </h2>
      </div>
      <div class="fileds">
        <div class="field">
          <p>הערותיך לקריאת שינוע זו:</p>
          <Textarea
            v-model="currentDetailByClick.finishComment"
            rows="5"
            cols="30"
            style="width:100%;"
          />
        </div>
        <div class="field">
          <p>סמן סטטוס טיפול:</p>
          <Dropdown
            v-model="currentDetailByClick.status"
            :options="['חדש','טופל','לא טופל','הודפס','בוטל']"
            style="width:100%;"
            placeholder="סמן סטטוס טיפול"
          />
        </div>
      </div>

      <div class="send-btn">
        <Button
          @click="handleFinishMashov"
          label="סיים תהליך"
          class="p-button-success"
          style="width:100%;"
        />
      </div>

      <div class="delete-btn">
        <Button
          @click="handleDelete"
          label="מחיקה"
          class="p-button-danger"
          style="width:100%;"
        />
      </div>
    </div>
  </Dialog>
</template>

<script>
/* eslint-disable */
import InputText from "primevue/inputtext";
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import Dialog from "primevue/dialog";
import swal from "sweetalert";
import {projectFirestore,projectFunctions} from '../../../../firebase/config'
import { computed, defineAsyncComponent, onMounted, onUnmounted, ref} from 'vue';
export default {
    components:{
      InputText,
      Dialog,
      Textarea,
      Dropdown,
      OnlyForDesktop:defineAsyncComponent(() =>  import('../../../Not_responsible/OnlyForDesktop.vue')),
      DisplayImages:defineAsyncComponent(() =>  import('./DisplayImages.vue'))
    },
    setup(){

        const start_date = ref('')
        const end_date = ref('')
        const from_branch = ref('')
        const to_branch = ref('')
        const selected_status = ref('חדש')
        const brnaches_options = ref([])

        const is_panding = ref(false)
        const selected_display_images = ref(null)
        const desktop = ref(true)
        const statusOptions=ref([
            'טופל',
            'לא טופל'
        ])
        const archiveBtn = ref(computed(()=>{
          if(archive.value == false) return 'הצג טופלו'
          return 'הצג לא טופלו'
        }))
        const archive = ref(false)
        const details=ref([])
        const showMashov=ref(false)
        const currentDetailByClick = ref({});
        const sortedByclick = ref({
            name: "",
            order: false
        });

        const total_price = computed(() => {
          return sortedDetails.value.reduce((a,c) => {
            return  a + (Number(c.price) || 0)
          },0)
        })
        const sortedDetails = ref(computed(()=>{
            if(!from_branch.value && !to_branch.value)  return filterData.value
            return filterData.value.filter(item => {
              return (!from_branch.value || item.selectedBranchFrom == from_branch.value) 
              && (!to_branch.value || item.selectedBranchTo == to_branch.value);
            })
           
        }))

        const handleSortTable = (name, order) => {
            sortedByclick.value.name = name;
            sortedByclick.value.order = order;
        };

        const handlePrint = (currentDetails) => {
          console.log(currentDetails);
        //   const divContents = `<div>
        //     <h3>
        //         שלום ${currentDetails.name},
        //     </h3>
        //     <h3>
        //         הזמנת שינוע מתאריך ${new Date(currentDetails.createdAt.seconds *1000).toLocaleDateString('he')}-${new Date(currentDetails.createdAt.seconds *1000).toLocaleTimeString('he')}
        //     </h3>
        //     <h3>
        //         מסניף/אתר ${currentDetails.selectedBranchFrom}
        //     </h3>
        //     <h3>
        //         לסניף/אתר ${currentDetails.selectedBranchTo}
        //     </h3>
        //     <h3>
        //         ${currentDetails.status=='טופל'?`<span style="color:green">טופלה בהצלחה<span>`:`<span style="color:red">סומנה כלא טופלה לעת עתה<span>`}
        //     </h3>
        //     <h3>
        //          תיאור בקשת השינוע: 
        //     </h3>

        //     <h4>
        //       ${currentDetails.comments || 'לא צויין'}
        //     </h4>
        //     <h3>
        //          הערות: ${currentDetails.finishComment || 'לא צויין'}
        //     </h3>
        //     <h3 style="color:red;">
        //         נא לשים לב להמשך עדכונים שיתקבלו אוטמטית מהמערכת בכל שלבי הטיפול!
        //     </h3>
        // </div>`
          const divContents = `<table
            style="width:100%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
          >
            <tr>
              <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                תאריך הבקשה
              </th>
              <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                ${new Date(currentDetails.createdAt.seconds * 1000).toLocaleString('he') }
              </td>
            </tr>
            <tr>
              <th style="border:1px solid #dddddd; text-align:right; padding:8px;">
                שם הסניף השולח
              </th>
              <td style="border:1px solid #dddddd; text-align:right; padding:8px;">
                ${ currentDetails.selectedBranchFrom }
              </td>
            </tr>
            <tr>
              <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                לסניף
              </th>
              <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                ${ currentDetails.selectedBranchTo }
              </td>
            </tr>
            <tr>
              <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                שם המבקש/ת
              </th>
              <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                ${ currentDetails.name }
              </td>
            </tr>
            <tr>
              <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                מידת הדחיפות
              </th>
              <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                ${ currentDetails.urgency }
              </td>
            </tr>
            <tr>
              <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                טלפון ליצירת קשר
              </th>
              <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                ${ currentDetails.phone }
              </td>
            </tr>
            <tr>
              <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                דוא"ל
              </th>
              <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                ${ currentDetails.email }
              </td>
            </tr>
            <tr>
              <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                תיאור הבקשה
              </th>
              <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                ${ currentDetails.comments }
              </td>
            </tr>
          </table>`
          var a = window.open('', '', 'height=800, width=600'); 
          a.document.write('<html dir=rtl>'); 
          a.document.write('<style>'); 
          a.document.write('@media print{#print-order-hide {visibility: hidden; } #delete-order-hide { visibility: hidden; } #mark-btn-hide {visibility: hidden;}}')
          a.document.write('</style>'); 
          a.document.write(divContents); 
          a.document.write('</body></html>'); 
          a.document.close(); 
          currentDetails.status = 'הודפס'
          projectFirestore.collection('Applications')
            .doc('oRfkXmNfGOHesWFGYwnN')
            .collection('Shinua')
            .doc(currentDetails.id)
            .set({status:'הודפס'},{merge:true})
          setTimeout(()=>{
            a.print(); 
          },500)
        }

        const filteredDataByDates = computed(() => {
          if(!start_date.value && !end_date.value) return sortedDetails.value
          return sortedDetails.value.filter(item => {
             // Convert Firestore Timestamp to JavaScript Date object
            const itemDate = new Date(item.createdAt.seconds * 1000 + item.createdAt.nanoseconds / 1000000).getTime();
            const startDate = start_date.value ? new Date(start_date.value).getTime() : null;
            const endDate = end_date.value ? new Date(end_date.value).getTime() + (24 * 60 * 60 * 1000 - 1) : null; // Adjust to include the entire end date

            // Check if the itemDate falls within the selected date range
            return (!startDate || itemDate >= startDate) && (!endDate || itemDate <= endDate);
          });
        });
        const filterData=ref(computed(()=>{
            if (sortedByclick.value.name == "") return filterBySearch.value;
            if (sortedByclick.value.name == "מסניף") {
                return filterBySearch.value.sort((a, b) => {
                    if (sortedByclick.value.order) {
                    return a.selectedBranchFrom > b.selectedBranchFrom ? 1 : b.selectedBranchFrom > a.selectedBranchFrom ? -1 : 0;
                    }
                    return a.selectedBranchFrom > b.selectedBranchFrom ? -1 : b.selectedBranchFrom > a.selectedBranchFrom ? 1 : 0;
                });
            }
            if (sortedByclick.value.name == "לסניף") {
                return filterBySearch.value.sort((a, b) => {
                    if (sortedByclick.value.order) {
                    return a.selectedBranchTo > b.selectedBranchTo ? 1 : b.selectedBranchTo > a.selectedBranchTo ? -1 : 0;
                    }
                    return a.selectedBranchTo > b.selectedBranchTo ? -1 : b.selectedBranchTo > a.selectedBranchTo ? 1 : 0;
                });
            }
            if (sortedByclick.value.name == "תאריך") {
                return filterBySearch.value.sort((a, b) => {
                    if (sortedByclick.value.order) {
                      return a.createdAt.toDate() > b.createdAt.toDate() ? 1 : b.createdAt.toDate() > a.createdAt.toDate() ? -1 : 0;
                    }
                      return a.createdAt.toDate() > b.createdAt.toDate() ? -1 : b.createdAt.toDate() > a.createdAt.toDate() ? 1 : 0;
                });
            }
            if (sortedByclick.value.name == "דואל") {
                return filterBySearch.value.sort((a, b) => {
                    if (sortedByclick.value.order) {
                    return a.email > b.email ? 1 : b.email > a.email ? -1 : 0;
                    }
                    return a.email > b.email ? -1 : b.email > a.email ? 1 : 0;
                });
            }
            if (sortedByclick.value.name == "טלפון") {
                return filterBySearch.value.sort((a, b) => {
                    if (sortedByclick.value.order) {
                    return a.phone > b.phone ? 1 : b.phone > a.phone ? -1 : 0;
                    }
                    return a.phone > b.phone ? -1 : b.phone > a.phone ? 1 : 0;
                });
            }
            if (sortedByclick.value.name == "דחיפות") {
                return filterBySearch.value.sort((a, b) => {
                    if (sortedByclick.value.order) {
                    return a.urgency > b.urgency ? 1 : b.urgency > a.urgency ? -1 : 0;
                    }
                    return a.urgency > b.urgency ? -1 : b.urgency > a.urgency ? 1 : 0;
                });
            }
            if (sortedByclick.value.name == "הערות") {
                return filterBySearch.value.sort((a, b) => {
                    if (sortedByclick.value.order) {
                    return a.comments > b.comments ? 1 : b.comments > a.comments ? -1 : 0;
                    }
                    return a.comments > b.comments ? -1 : b.comments > a.comments ? 1 : 0;
                });
            }
            if (sortedByclick.value.name == "סטטוס") {
                return filterBySearch.value.sort((a, b) => {
                    if (sortedByclick.value.order) {
                    return a.status > b.status ? 1 : b.status > a.status ? -1 : 0;
                    }
                    return a.status > b.status ? -1 : b.status > a.status ? 1 : 0;
                });
            }
            if (sortedByclick.value.name == "פותח הקריאה") {
                return filterBySearch.value.sort((a, b) => {
                    if (sortedByclick.value.order) {
                    return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
                    }
                    return a.name > b.name ? -1 : b.name > a.name ? 1 : 0;
                });
            }
        }))

        const search = ref("");

        const filterBySearch = ref(computed(() => {
            if (search.value == "") return details.value;
            return details.value.filter(detail => {
            if(new Date(detail.createdAt.seconds * 1000).toLocaleDateString('he').includes(search.value)) return detail
            if (detail.name.includes(search.value)) return detail;
            if (detail.selectedBranchFrom.includes(search.value)) return detail;
            if (detail.selectedBranchTo.includes(search.value)) return detail;
            if (detail.status.includes(search.value)) return detail;
            if (detail.urgency.includes(search.value)) return detail;
            if (detail.phone.includes(search.value)) return detail;
            if (detail.email.includes(search.value)) return detail;
            if (detail.comments.includes(search.value)) return detail;
        });
        }));
        
        const handleMashov = detail => {
            currentDetailByClick.value = detail;
            console.log(currentDetailByClick.value);
            showMashov.value = !showMashov.value;
        };

        const handleFinishMashov=async()=>{
            if(!currentDetailByClick.value.hasOwnProperty('finishComment')){
                swal("נא לשים לב!", "עליך למלא את שדה ההערות ", "error");
                return
            }
            if(currentDetailByClick.value.hasOwnProperty('finishComment')){
                if(currentDetailByClick.value.finishComment==''){
                    swal("נא לשים לב!", "עליך למלא את שדה ההערות ", "error");
                    return
                }
            }
            if(currentDetailByClick.value.status == 'חדש'){
                swal("נא לשים לב!", "עליך לסמן סטטוס טיפול", "error");
                return
            }
            
            console.log(currentDetailByClick.value);

            await projectFirestore.collection('Applications')
            .doc('oRfkXmNfGOHesWFGYwnN')
            .collection('Shinua')
            .doc(currentDetailByClick.value.id)
            .set(currentDetailByClick.value,{merge:true})

             swal("תהליך שינוע זה נהפך לסטטוס טופל נשלח מייל לפותח הקריאה לעדכון התהליך","הצלחה","success");
             
             sendEmailToUser(currentDetailByClick.value);
             showMashov.value=!showMashov.value
        }

        function sendEmailToUser(currentDetails) {
           projectFunctions.httpsCallable('send_email')({
            html: `<div>
            <h3>
                שלום ${currentDetails.name},
            </h3>
            <h3>
                הזמנת שינוע מתאריך ${new Date(currentDetails.createdAt.seconds *1000).toLocaleDateString('he')}-${new Date(currentDetails.createdAt.seconds *1000).toLocaleTimeString('he')}
            </h3>
            <h3>
                מסניף/אתר ${currentDetails.selectedBranchFrom}
            </h3>
            <h3>
                לסניף/אתר ${currentDetails.selectedBranchTo}
            </h3>
            <h3>
                ${currentDetails.status=='טופל'?`<span style="color:green">טופלה בהצלחה<span>`:`<span style="color:red">סומנה כלא טופלה<span>`}
            </h3>
            <h3>
                 תיאור בקשת השינוע: 
            </h3>

            <h4>
              ${currentDetails.comments || 'לא צויין'}
            </h4>
            <h3>
                 הערות: ${currentDetails.finishComment}
            </h3>
            <h3 style="color:red;">
                נא לשים לב להמשך עדכונים שיתקבלו אוטמטית מהמערכת בכל שלבי הטיפול!
            </h3>
            </div>`,
            destinations:[`${currentDetails.email}`],
            from:"מערכת שינוע והובלה",
            title:"בקשת שינוע נסגרה"
          }) 
        }

        const handleDelete = () => {
            swal({
                title: "האם את/ה בטוח/ה?",
                text: "לאחר המחיקה לא יהיה ניתן לשחזר את הרשומה!",
                icon: "warning",
                buttons: true,
                dangerMode: true
            }).then(async willDelete => {
                if (willDelete) {
                  await projectFirestore.collection("Applications").doc("oRfkXmNfGOHesWFGYwnN").collection("Shinua")
                  .doc(currentDetailByClick.value.id).delete();
                        const index=details.value.findIndex(detail=>detail.id==currentDetailByClick.value.id)
                        details.value.splice(index,1)
                        showMashov.value=!showMashov.value
                  swal("הרשומה נמחקה בהצלחה", {
                        icon: "success"
                    });
                }
            });
        };

        window.addEventListener('resize', ()=>{
            if(window.innerWidth<600){
                desktop.value = false
            }else{
                desktop.value = true
            }
        });

        onMounted(async()=>{
            if(window.innerWidth<600){
                desktop.value = false
            }else{
                desktop.value = true
            }
            is_panding.value = true
            await getDetailsFromDb()
            details.value = details.value.sort((a,b)=>b.createdAt.toDate() - a.createdAt.toDate());
            orgenize_branches(details.value)
            is_panding.value = false
        })

        onUnmounted(()=>{
          window.removeEventListener('resize', ()=>{
                if(window.innerWidth<600){
                    desktop.value = false
                }else{
                    desktop.value = true
                }
            });
        })
        const getDetailsFromDb = async () => {
            const docs = await projectFirestore.collection("Applications").doc("oRfkXmNfGOHesWFGYwnN").collection("Shinua").get();
            details.value = docs.docs.map(doc=>doc.data())
        };

        const handle_change_price = async(detail) => {
          console.log(detail);
          await projectFirestore.collection("Applications").doc("oRfkXmNfGOHesWFGYwnN")
          .collection("Shinua").doc(detail.id).set({
            price:Number(detail.price)
          },{merge:true})
        }

        const orgenize_branches = (details) => {
          const set = new Set()
          details.forEach(detail => {
            set.add(detail.selectedBranchFrom)
            set.add(detail.selectedBranchTo)
          })

          brnaches_options.value = [...set]
        }

        return {
          handlePrint,
          handleDelete,
          handleFinishMashov,
          handleMashov,
          handleSortTable,
          handle_change_price,
          desktop,
          archive,
          archiveBtn,
          statusOptions,
          showMashov,
          currentDetailByClick,
          sortedDetails,
          search,
          filterBySearch,
          sortedByclick,
          selected_display_images,
          is_panding,
          total_price,
          start_date,
          end_date,
          filteredDataByDates,
          brnaches_options,
          from_branch,
          to_branch,
          selected_status
        }
    }
}
</script>

<style scoped>
    .manage-shinia-container{
        width: 80vw;
        height: 75vh;
    }
    .header{
        width: 100%;
        height: 5vh;
        display: flex;
    }
    .header .search {
        margin-left: 5px;
    }
    .content {
        margin-top: 10px;
        width: 100%;
        height: 70vh;
    }
    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    td,
    th {
        border: 1px solid #dddddd;
        text-align: right;
        padding: 8px;
    }
    th {
        cursor: pointer;
    }

    tr:nth-child(even) {
        background-color: #dddddd;
    }

    tr:hover {
        background: lightskyblue;
    }

    i {
        cursor: pointer;
        user-select: none;
    }

    i:hover {
        color: #f7b00c;
    }

    .mashov-container {
        position: relative;
        width: 50vw;
        height: 60vh;
    }

    .mashov-container .explaination h4 {
        margin-bottom: 5px;
    }

    .mashov-container .fileds {
        width: 100%;
        height: 20vh;
        margin-top: 15px;
    }
    .mashov-container .field {
        width: 100%;
        margin-bottom: 10px;
    }
    .mashov-container .field p {
        font-weight: bold;
    }

    .delete-btn {
        position: absolute;
        width: 20%;
        bottom: 0;
        left: 0;
    }
    .send-btn{
        margin-top: 35px;
    }

    @media only screen and (max-width: 600px){
         .mashov-container .fileds{
             height: 30vh;
         }
        .mashov-container {
            position: relative;
            width: 80vw;
            height: 70vh;
        }
        .delete-btn {
            position: unset;
            width: 100%;
        }
    }

    ::v-deep(.p-dropdown .p-dropdown-clear-icon){
      right: 2px;
    }

</style>